import React from 'react';

import formatMoney from '@/utils/formatter/formatMoney';
import { DevtoolsWrapper } from '../DevtoolsWrapper';
import { DetailList } from './DetailList';

const example = () => {
  return (
    <DevtoolsWrapper>
      <DetailList
        items={[
          {
            label: 'My first',
            value: 'the value',
          },
          {
            label: 'My second',
            value: formatMoney(10_000),
          },
        ]}
      />
    </DevtoolsWrapper>
  );
};

export default example;
